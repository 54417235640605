import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
// import PropTypes from 'prop-types';



import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
//import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
//import List from 'common/src/components/List';

const BlogPostList = ({ row, col, data, title, meta }) => {
  // // Add all classs to an array
  // const addAllClasses = ['blog_post'];

  // // className prop checking
  // if (className) {
  //   addAllClasses.push(className);
  // }

  const item = post => {
    return (
      <Box key={post.node.frontmatter.path} {...row}>
        {/* PODCAST THUMBNAIL */}
        <Box {...col}>
          <Link to={post.node.frontmatter.path}>
            <Image
              src={post.node.frontmatter.image}
              alt={post.node.frontmatter.title}
              style={{ borderRadius: '10px', height: '100%', width: '100%' }}
            />
          </Link>
        </Box>
        {/* TITLE AND METADATA */}
        <Box {...col}>
          <Link to={post.node.frontmatter.path}>
            <Text {...title} content={post.node.frontmatter.title} style={{color: "#ececec"}} />
          </Link>
          <Text {...meta} content={post.node.frontmatter.date} />
        </Box>
      </Box>
    );
  };

  return (
    <Box {...row}>{data.allMarkdownRemark.edges.map(post => item(post))}</Box>
  );
};

BlogPostList.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  menuWrapper: PropTypes.object,
  data: PropTypes.object,
};

BlogPostList.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '40px',
    //mb: '50px',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pl: ['30px', '30px', '30px', '25px', '45px'],
    pr: ['30px', '30px', '30px', '25px', '45px'],
    // mb: ['50px', '70px'],
  },
  title: {
    // fontFamily: 'Raleway',
    fontSize: ['18px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.020em',
    lineHeight: '1.5',
    ml: ['30px'],
    mb: ['10px', '13px'],
    textAlign: 'center',
  },
  meta: {
    // fontFamily: 'Raleway',
    fontSize: '12px',
    fontWeight: '400',
    color: '#5d646d',
    letterSpacing: '-0.025em',
    lineHeight: '1.88',
    ml: ['30px'],
    mb: 0,
    textAlign: 'center',
  },
};

export default BlogPostList;
